import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Link, graphql } from "gatsby";
import { formatDate } from "../utils/utils";
import { GatsbyImage } from "gatsby-plugin-image";
import CircleArrow from "../components/CircleArrow";
import { isMobile } from "react-device-detect";
import PopupGallery from "../components/Gallery";



const Event = ({ data }) => {
  const { title, publishDate, image, startDate, endDate, place, openingDate, gallery } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const [currPage, setCurrPage] = React.useState(1);
  const [shpwPopup, setShowPopup] = React.useState(false);
  const pageSize = isMobile ? 2 : 3;
  const [currImageIndex, setCurrImageIndex] = React.useState(0);

  const [galleryImages, setGalleryImages] = React.useState(gallery.slice(0, pageSize).map((image, index) => (
    {
      image: image.image.childImageSharp.gatsbyImageData,
      isActive: index === 0 ? true : false
    }
  )
  )
  )

  const showGalleryPopup = (index) => {
    setShowPopup(true);
    setCurrImageIndex(index + (currPage - 1) * pageSize);
  }





  const changeGalleryImage = (index) => {
    const newGalleryImages = [...galleryImages];
    newGalleryImages.forEach((image, i) => {
      if (i === index) {
        image.isActive = true;
      } else {
        image.isActive = false;
      }
    })
    setGalleryImages(newGalleryImages);
  }

  const changePage = (isNext) => {

    if (isNext) {

      if (currPage < Math.ceil(gallery.length / pageSize)) {
        setCurrPage(currPage + 1);
      }
    } else {
      if (currPage > 1) {
        setCurrPage(currPage - 1);
      }
    }
  }

  React.useEffect(() => {
    setGalleryImages(gallery.slice((currPage - 1) * pageSize, currPage * pageSize).map((image, index) => (
      {
        image: image.image.childImageSharp.gatsbyImageData,
        isActive: index === 0 ? true : false
      }
    )
    ))
  }, [currPage])

  return (
    <Layout>
      <div className="section-container">
        <section className="section">
          <h1 style={{
            marginBottom: "1rem"
          }}>{title}</h1>
          <p>{`Opublikowano ${formatDate(new Date(publishDate))}`}</p>
        </section>
        <div className="section-container">
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} style={{ width: "100%" }} />
        </div>
        <div className="section-container">
          <hr className="hr" />
          <section className="section">
            <h5 className="h5-black">DATA</h5>
            <p>{`${formatDate(new Date(startDate))} - ${formatDate(new Date(endDate))}`}</p>
            <h5 className="h5-black">WERNISAŻ</h5>
            <p>{formatDate(new Date(openingDate))}</p>
            <h5 className="h5-black">MIEJSCE</h5>
            <Link to="/map"><p style={{ textDecoration: "underline" }}>{place}</p></Link>
          </section>
          <hr className="hr" />
        </div>
      </div>
      <div className="section-container">
        <section className="section inner-html" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div className="section-container">
        <section className="section">
          <div className="gallery" >
            {
              shpwPopup ? <PopupGallery images={gallery} closePopup={() => setShowPopup(false)} currentImage={currImageIndex} /> : null
            }
            {galleryImages.map((image, index) => (
              <div key={index} className={`gallery-image ${image.isActive ? "active" : ""}`} onClick={() => showGalleryPopup(index)}>
                <GatsbyImage key={index} image={image.image} alt={title} />
              </div>
            ))}
          </div>
          <div className="gallery-pagination">
            <CircleArrow direction="left" onClick={() => changePage(false)} />
            <CircleArrow direction="right" onClick={() => changePage(true)} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Event;

export const pageQuery = graphql`
  query EventByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        publishDate
        startDate
        endDate
        openingDate
        place
        gallery {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, height: 500)
          }
        }
      }
    }
  }
`
